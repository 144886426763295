import Tab from 'bootstrap/js/src/tab';
import Swiper from 'swiper';

document.addEventListener('DOMContentLoaded', function () {
  let hash = window.location.hash;
  let tab;
  let selectedTab = 'tab-populär';
  let slides = document.querySelectorAll('[data-bs-toggle="tab"]');
  let lastIndex = slides.length - 1;
  let swiperList = document.querySelectorAll('[data-swiper]');

  swiperList.forEach(function (swiperElement) {
    let swiper = new Swiper(swiperElement, {
      slidesPerView: 'auto',
      watchOverflow: true,
      centeredSlides: true,
      centeredSlidesBounds: true,
    });

    if (hash !== '') {
      setTimeout(function () {
        if (location.hash) {
          window.scrollTo(0, 0);
        }
      });
      selectedTab = hash.replace('#', 'tab-');
    }
    tab = document.getElementById(decodeURI(selectedTab));

    slides.forEach(function (slide) {
      slide.addEventListener('show.bs.tab', function (e) {
        let index = Array.from(slides).indexOf(e.target);
        swiper.slideTo(index, 600);
        swiper.el.classList.remove('anw-first-tab-selected');
        swiper.el.classList.remove('anw-last-tab-selected');
        if (index === 0) {
          swiper.el.classList.add('anw-first-tab-selected');
        } else if (index === lastIndex) {
          swiper.el.classList.add('anw-last-tab-selected');
        }
      });
    });

    if (tab) {
      const tabInstance = new Tab(tab);
      tabInstance.show();
    }
  });
});
